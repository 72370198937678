import general from '../inc/general';

(function ($, dataLayer) {
    window.getparam = window['getparam'] ||
        function (name) {
            var re = new RegExp("[&\?]" + name + "=([^&]*)", "i");
            var m = document.location.search.match(re);
            return (m != null && m.length > 1) ? m[1] : "";
        };


    window.windowOnLoad = function (callback) {
        if (window.addEventListener) {
            window.addEventListener('load', callback, false);
        } else if (window.attachEvent) {
            window.attachEvent('onload', callback);
        }
    };

    window.savecookie = function (a, b, c) {
        var d = new Date();
        d.setTime(d.getTime() + 864E5 * ("undefined" != typeof c ? c : 3));
        c = "; expires\x3d" + d.toGMTString();
        document.cookie = a + "\x3d" + b + c + "; path\x3d/;domain\x3d" + document.domain.replace(/^[^\.]+/, '')
    };

    window.readcookie = function (a) {
        a = new RegExp("(?:^| )" + a + "\x3d([^;]*)", "i");
        try {
            let m = document.cookie.match(a);
            if (m && m.length === 2) {
                return m[1];
            }
        } catch (x) { }
        return null;
    };

    $('#cookie-notif')[window.readcookie('fx_cn') ? 'hide' : 'show']();

    window.geoipcookie = window.readcookie('geoip');


    window.savePPCookieA = function () {
        window.savecookie("fx_cn", "1");
        $('#cookie-notif').hide();

    };

    window.scrollingTasks = [];


    window.__dlw = {
        rc: function (e) {
            var t = new RegExp("(?:^| )" + e + "=([^;]*)", "i"),
                i = document.cookie.match(t);
            return i && 2 === i.length ? decodeURIComponent(i[1]) : null;
        },
        sc: function (e, t, i) {
            var n = "";
            if (i) {
                var l = new Date();
                l.setTime(l.getTime() + 24 * (void 0 !== i ? i : 3) * 60 * 60 * 1e3), n = "; expires=" + l.toGMTString()
            }
            document.cookie = e + "=" + encodeURIComponent(t) + n + "; path=/;";
        },
        store: function (e, t, i) {
            i = i || 3;
            var n = {};
            if (n[e] = t, window.dataLayer.push(n), 3 !== i) {
                var l = window.__dlw.rc("cdl" + i),
                    r = [];
                if (null != l)
                    for (var c = (r = l.split(";")).length; c--;)
                        if (r[c].split(":")[0] === e) {
                            r.splice(c, 1);
                            break;
                        }
                r.push(e + ":" + t.replace(/;/g, ""));
                window.__dlw.sc("cdl" + i, r.join(";"), 1 == i && 1e3);
            }
        },
        init: function () {
            var e = (window.__dlw.rc("cdl1") || "") + ";" + (window.__dlw.rc("cdl2") || "");
            if (";" !== e) {
                let n;
                for (let t = e.split(";"), n = {}, i = t.length; i--;) "" !== t[i] && (n[t[i].split(":")[0]] = t[i].split(":")[1]);
                window.dataLayer.push(n);
            }
        }
    };


    let scrolling = false;




    window.isInViewport = function (elem) {
        let elementTop = elem.offset().top;
        let elementBottom = elementTop + elem.outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        //return elementBottom > viewportTop && elementTop < viewportBottom;
        return elementTop < viewportBottom;
    };

    function lazyImageBF() {
        var lazyImages = [].slice.call(document.getElementsByClassName("lazy-image-bf"));
        lazyImages.forEach.call(lazyImages, function (lazyImage) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy-image-bf");
        });
    }


  
    let main_header = $('#header');






    general();
    window.__dlw.init();


    if (document.location.hash) {
        var hash = document.location.hash;
        try {
            if ($(hash) && $(hash).length > 0) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top - main_header.outerHeight() 
                }, 1e3);
            }
        }
        catch (err) { }

    }


    /*******/




    //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf
    let lazyImage_wrapper = $('.lazy-image-bf');
    let loadingLazyImagesBF = false, lazyImagesBFLoaded = false;
    let footer_wrapper = $('.footer-section');
    let loadingFooter = false, footerLoaded = false;



    function doScrolling() {

        


    //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf

    if (lazyImage_wrapper.length > 0 && !loadingLazyImagesBF && !lazyImagesBFLoaded && window.isInViewport(lazyImage_wrapper)) {
        loadingLazyImagesBF = true;
        if (!lazyImagesBFLoaded) {
            lazyImageBF();
            loadingLazyImagesBF = false;
            lazyImagesBFLoaded = true;
        }
    }

    if (footer_wrapper.length > 0 && !loadingFooter) {

        loadingFooter = true;
        !footerLoaded && import(/* webpackChunkName: "footer" */'../components/footer/footer')
            .then(module => {
                loadingFooter = false;
                footerLoaded = true;
                module.default();
            });
    }


    
    }


    window.scrollingTasks.push(doScrolling);





    function scrollingTimer() {
        if ($(document).height() <= $(window).height()) {
            scrolling = true;
        }
        if ($(window).scrollTop() > 1) {
            scrolling = true;
        }
        if (window.callDoScrolling) scrolling = true;
        // if (scrolling) doScrolling();

        if (scrolling) window.scrollingTasks.forEach(function (task) {
            task();
        });
        window.setTimeout(scrollingTimer, 250);
    }

    scrollingTimer();

    //window.dataLayer.push({"event":"tracking-init"});




    $(window).on('scroll resize', function (e) {
        scrolling = true;

    });


})(window.jQuery, window.dataLayer || []);

