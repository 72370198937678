const config = {
  "broker": "108",
  "name": "English",
  "registrationBundle": "bundle_english",
  "defaultCountryName": "South Africa",
  "defaultCountryCode": "ZA",
  "fxgeoip_code": "ZA",
  languageCode: 'en',
  languageName:'English',
  spokenLanguageCode:'en',
  direction:'ltr',
  footerDisclaimer:'954',
  brand: 'iforexcrypto'
};
module.exports = config;